<template>
  <div class="container">
    <header-back name='绑定银行卡'></header-back>
    <section class="top44">
      <div class="abb-bank">
        <div class='bank-tit'>
          <div class="tit1">绑定银行卡</div>
          <div class="tit2">请绑定持卡人本人的银行卡</div>
        </div>
        <div class="bank-f">
          <van-cell-group style="background-color:transparent">
            <van-field clearable v-model="backMap.nickname" label="户名：" placeholder="请输入户名" />
            <van-field clearable v-model="backMap.idCard" label="身份证：" placeholder="请输入身份证" />
            <van-field clearable type="tel" v-model="backMap.number" label="卡号：" placeholder="请输入卡号" />

            <van-field clearable type="tel" v-model="backMap.mobile" label="预留号码：" placeholder="请输入预留号码" />
            <div class="mt16"></div>
            <van-field v-model="backName" readonly clickable name="picker" label="银行名称：" placeholder="请选择银行"
              @click="showPicker = true" />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker show-toolbar :columns="columnsBank" @confirm="onConfirmBank" @cancel="showPicker = false" />
            </van-popup>
            <van-field readonly clickable name="area" label-width='7em' :value="areaName" label="银行所在地区："
              placeholder="请选择" @click="showArea = true" />
            <van-popup v-model="showArea" position="bottom">
              <van-picker show-toolbar :columns="columnsArea" @cancel="showArea = false" @confirm="onConfirmArea" />

            </van-popup>

            <!-- <van-field
              v-model="backMap.backName2"
              label="所属支行："
              placeholder="请选择所属支行"
            /> -->
            <van-field v-model="backName2" readonly clickable
              :disabled="backMap.backName === '' || backMap.province === ''" name="picker" label="银行支行："
              placeholder="请选择支行" @click="onBackClick" />
            <van-popup v-model="showPicker2" position="bottom">
              <van-picker show-toolbar :columns="columns" @confirm="onConfirmBranche" @cancel="showPicker2 = false" />
            </van-popup>
            <van-field v-if="backName2 === '其他支行'" clearable v-model="bankBranchName" label="银行名称："
              placeholder="请输入银行名称" />
            <van-field v-if="backName2 === '其他支行'" clearable v-model="bankBranchName1" label="所属支行："
              placeholder="请输入所属支行" />
            <van-field clearable v-model="backMap.alipayNo" label="支付宝：" placeholder="请输入支付宝账号" />
          </van-cell-group>
        </div>
        <div class="bank-btn">
          <van-button :color="variables.textColor" bindtap="onNext" size="small" type="primary"
            @click='addBack'>下一步</van-button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import variables from "@/styles/variables.scss";
import areaList from "@/utils/area.js";
import API from "../../api/http";
import validateRule from "@/utils/validateRule";
export default {
  components: { HeaderBack },
  data() {
    return {
      backMap: {
        nickname: "",
        idCard: "",
        number: "",
        mobile: "",
        BankCode: "",
        bankBrancheId: "",
        province: "",
        alipayNo: ""
      },
      backName: "",
      backName2: "",
      areaName: "",
      showPicker: false,
      showPicker2: false,
      columnsBank: [],
      columnsArea: [],
      columns: [],
      showArea: false,
      areaList: areaList,
      bankBranchName: "",
      bankBranchName1: ""
    };
  },
  computed: {
    variables() {
      return variables;
    }
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      // if (utils.getSessionStorage("bank")) {
      //   let bankList = utils.getSessionStorage("bank");
      //   let areaList = utils.getSessionStorage("area");
      //   this.columnsBank = JSON.parse(bankList);
      //   this.columnsArea = JSON.parse(areaList);
      //   return;
      // }
      API.getBankCardList().then(res => {
        let result = res.data;
        this.columnsBank = result.bank_list.map(item => {
          return {
            text: item.bankAlias,
            code: item.bankAliasCode
          };
        });
        this.columnsArea = result.regions.map(item => {
          return {
            text: item.name,
            code: item.code,
            children: item.childrenCitys.map(item => {
              return {
                text: item.name,
                code: item.code
              };
            })
          };
        });
        // utils.setSessionStorage("bank", this.columnsBank);
        // utils.setSessionStorage("area", this.columnsArea);
      });
    },
    getBrancheList() {
      //支行列表
      if (this.backMap.backName === "" || this.backMap.province === "") {
        return;
      }
      let data = {
        bankCode: this.backMap.BankCode,
        cityCode: this.backMap.province
      };
      API.getBankBranchList(data).then(res => {
        let reault = res.data;
        this.columns = reault.list.map(item => {
          return {
            text: item.bankBranchName,
            code: item.bankBranchId
          };
        });
      });
    },
    setAge(val) {
      let val1 = val.slice(0, 4) + '-' + val.slice(4, 6) + '-' + val.slice(6, 8)
      // 根据出生日期 计算你年龄
      let age = 0
      let diff = 0
      // 出生日期
      const birArr = new Date(val1)
      const birYear = birArr.getFullYear()
      const birMonth = birArr.getMonth() + 1
      const birDay = birArr.getDate()
      // 当前时间
      const d = new Date()
      const nowYear = d.getFullYear()
      const nowMonth = d.getMonth() + 1 // 记得加1
      const nowDay = d.getDate()
      if (birYear < nowYear) {
        age = nowYear - birYear

        if (birMonth > nowMonth) {
          // 出生月份大于当前月份
          diff = -1
        } else if (birMonth === nowMonth && birDay > nowDay) {
          // 出生月份等于当前月份，出生日期大于当前日期
          diff = -1
        }
      }
      return age + diff
    },
    addBack() {
      if (!this.backMap.nickname) {
        Toast("请输入户名");
        return;
      }
      if (!this.backMap.idCard) {
        Toast("请输入身份证号码");
        return;
      }
      if (!this.backMap.number) {
        Toast("请输入卡号");
        return;
      }
      if (!this.backMap.mobile) {
        Toast("请输入预留号码");
        return;
      }
      if (!this.backName) {
        Toast("请选择银行");
        return;
      }
      if (!this.areaName) {
        Toast("请选择银行所在地区");
        return;
      }
      if (!this.backName2) {
        Toast("请选择支行");
        return;
      }

      if (!this.backMap.alipayNo) {
        Toast("请输入支付宝账号");
        return;
      }

      // if (
      //   !validateRule.checkBankCard(this.backMap.number.replace(/\s+/g, ""))
      // ) {
      //   Toast("银行卡号输入错误！");
      //   return;
      // }

      if (!validateRule.checkIdCard(this.backMap.idCard)) {
        //省份证格式不正确
        Toast("身份证输入错误！");
        return;
      }
      if (!validateRule.checkPhoneAndTel(this.backMap.mobile)) {
        //手机号格式不正确
        Toast("手机号码输入错误！");
        return;
      }
      if (this.backName2 === "其他支行" && !this.bankBranchName.trim()) {
        Toast("请输入银行名称");
        return;
      }
      if (this.backName2 === "其他支行" && !this.bankBranchName1.trim()) {
        Toast("请输入银行支行名称");
        return;
      }

      let age = this.setAge(this.backMap.idCard.slice(6, 14))
      if (age < 16 || age > 65) {
        Toast("年龄区间在16~65之间");
        return;
      }
      let data = {
        nickname: this.backMap.nickname,
        idCard: this.backMap.idCard,
        number: this.backMap.number.replace(/\s+/g, ""),
        mobile: this.backMap.mobile,
        bankBrancheId: this.backMap.bankBrancheId,
        alipayNo: this.backMap.alipayNo
      };
      if (this.bankBranchName.trim()) {
        data.customBank = this.bankBranchName + "," + this.bankBranchName1;
      }
      API.postAddBank(data).then(res => {
        Toast("添加成功");
        this.$router.go(-1);
      });
    },
    onConfirmBank(value, index) {
      //银行
      if (value.code !== this.backMap.BankCode) {
        this.backMap.backName2 = "";
        this.backName2 = "";
      }
      this.backMap.BankCode = value.code;
      this.backName = value.text;
      this.showPicker = false;
      this.getBrancheList();
    },
    onConfirmBranche(value) {
      //支行

      if (value) {
        this.backMap.bankBrancheId = value.code;
        this.backName2 = value.text;
      }

      this.showPicker2 = false;
    },
    onBackClick() {
      if (this.backMap.backName === "" || this.backMap.province === "") {
        Toast("请先选择银行和所在地区");
        // showPicker2 = true = true
      } else {
        this.showPicker2 = true;
      }
    },
    onConfirmArea(value, index) {
      //银行地区
      this.areaName = value.join("/");
      let i = index[0];
      let j = index[1];
      let code = this.columnsArea[i].children[j].code;
      if (this.backMap.province !== code) {
        this.backMap.backName2 = "";
        this.backName2 = "";
      }
      this.backMap.province = code;
      this.showArea = false;
      this.getBrancheList();
      console.log("kk", this.backMap);
    }
  }
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";

.container {
  padding: 0 15px;
  box-sizing: border-box;
}

.bank-tit {
  padding-top: 30px;
  text-align: center;
  color: #030303;
  font-size: 20px;
  font-weight: 500;
}

.tit2 {
  margin-top: 10px;
  font-size: 16px;
  color: #292929;
}

.bank-f {
  margin-top: 30px;
}

.bank-btn {
  width: 100%;
  text-align: center;
  margin-top: 30px;

  .van-button {
    padding: 10px 30px;
  }
}
</style>
